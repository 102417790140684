import xhr from './xhr/';
/**
 * 预警管理相关的 API
 */
class WarningService {
	// 预警管理首页 获取后台传过来的select配置项
	getWarningSelect(data) {
		return xhr({
			method: 'get',
			url: '/warning/index',
			params: data,
		});
	}

	// 搜索
	getWarningList(data) {
		return xhr({
			method: 'get',
			url: '/warning/list',
			params: data,
		});
	}

	// 删除问题或记录
	deleteWarning(data) {
		return xhr({
			method: 'post',
			url: '/warning/delete',
			params: data,
		});
	}

	// 处理预警
	handleWarning(data) {
		return xhr({
			method: 'post',
			url: '/warning/handle',
			params: data,
		});
	}
}

// 实例化后导出，全局单例
export default new WarningService();
